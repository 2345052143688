import {GdkAccordion} from "@gdk/accordion";
import baseComponent from "@gdk/base-component";
import {IBaseComponentOptions} from "@gdk/base-component";
import Version from "@gdk/version";

const component = "Navigation";
const versions = [
    { version: "4.1.0", release: "4.11.24"},
    { version: "4.0.0", release: "11.2.23"},
    { version: "3.2.0", release: "10.19.23"},
    { version: "3.1.0", release: "10.5.23"},
    { version: "3.0.0", release: "8.31.23"},
];

const validateSettings = [
    {
        setting:  "content",
        isRequired:  true,
        validate:  "type",
        possibleValues:  ["string", "object"],
        errorMessage:  ["GDK Navigation :  Content must be defined and set to a DOM selector or Node"]
    }
];

export {IBaseComponentOptions};

/**
 * @desc GDK Navigation JavaScript Class
 *
 * @example <caption>JS Instantiation</caption>
 * var navigation = new GDK.Navigation({
 *     content: "#primary-navigation"
 * });
 */
class GdkNavigation {
    _internalVars:  {
        contentType:  string | HTMLElement;
        node:  HTMLElement;
    };

    readonly _defaults:  object;

    readonly _options:  IBaseComponentOptions;

    /**
     * @param {Object} options Settings for the instantiation.
     *
     * @param {string|HTMLElement} options.content A reference to the component node
     */
    constructor(options:  IBaseComponentOptions) {
        /**
         * @ignore
         */
        this._internalVars = {
            node:  null, 
            contentType:  null
        };

        /**
         * @ignore
         */
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            /**
             * @ignore
             */
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            initHeaderLinks.call(this);
            applyAriaHasPopupTier1Links.call(this);
        }
    }

    /**
     * @desc Opens a specific tier navigation panel
     * @param {HTMLElement} panelElement The navigation panel to open
     */
    openNavigationPanel(panelElement: HTMLElement): void {
        closeNav.call(this);
        if (panelElement.classList.contains("panel-wrapper")) {
            openNavPanel.call(this, panelElement);
        } else if (panelElement.classList.contains("nav-secondary-panel")) {
            openNavPanel.call(this, panelElement.parentElement.parentElement.parentElement);
            openTier2Panel.call(this, panelElement);
        }
    }

    /**
     * @desc Closes navigation
     */
    closeNavigation(): void {
        closeNav.call(this);
    }

    /**
     * @desc Removes the node from the dom and any events attached
     */
    destroy(): void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (const variableKey in this) {
            if (Object.prototype.hasOwnProperty.call(this, variableKey)) {
                delete this[variableKey];
            }
        }
    }

}

// Private Methods

function setLocalVars(): void {
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }

    // This code sets the internal variable for skipToContent based on whether the javascript vs web component version of the component is used.
    if (document.getElementsByTagName("gds-skip-to-content")[0]) {
        this._internalVars.skipToContent = document.getElementsByTagName("gds-skip-to-content")[0].shadowRoot.querySelector("a");
    } else if (document.querySelector(".skip-to-content")) {
        this._internalVars.skipToContent = document.querySelector(".skip-to-content");
    }


    this._internalVars.body = document.getElementsByTagName("body")[0];
    this._internalVars.html = document.getElementsByTagName("html")[0];
    
    this._internalVars.header = document.querySelector("#primary-header");
    this._internalVars.headerMenuLinks = this._internalVars.header.querySelectorAll(".header-links a");
    this._internalVars.hamburgerMenu = this._internalVars.header.querySelector(".hamburger-menu");
    this._internalVars.headerLogo = this._internalVars.header.querySelector(".header-logo a");

    this._internalVars.nav = document.querySelector("nav");
    this._internalVars.panels = this._internalVars.nav.querySelectorAll(".panel-wrapper");
    this._internalVars.navBg = this._internalVars.nav.querySelector(".nav-background");
    this._internalVars.navMenu = this._internalVars.nav.querySelector(".nav-menu");
    this._internalVars.navTier1Links = this._internalVars.nav.querySelectorAll(".nav-items a");
    this._internalVars.navTier1 = this._internalVars.nav.querySelector(".nav-primary-tier");
    this._internalVars.navTier1Li = this._internalVars.nav.querySelectorAll(".nav-primary-tier > li");
    this._internalVars.navTier2 = this._internalVars.nav.querySelector(".nav-secondary-tier");
    this._internalVars.navTier2Panels = this._internalVars.nav.querySelectorAll(".nav-secondary-panel");
    this._internalVars.navBack = this._internalVars.nav.querySelector(".nav-back");
    this._internalVars.accordions = this._internalVars.nav.querySelectorAll(".accordion");
    this._internalVars.closeButtons = this._internalVars.nav.querySelectorAll(".btn-close");

    this._internalVars.navOpen = false;
    this._internalVars.navState = "desktop";
    this._internalVars.menuOpen = false;
    this._internalVars.currentOpenPanel = null;
    
    this._internalVars.skipToContentBlur = skipToContentBlur.bind(this);
    this._internalVars.skipToContentFocus = skipToContentFocus.bind(this);
    this._internalVars.menuClick = menuClick.bind(this);
    this._internalVars.headerHoverLinksWidth = headerHoverLinksWidth.bind(this);
    this._internalVars.navBgClick = closeNav.bind(this);
    this._internalVars.navTier1MenuClick = navTier1MenuClick.bind(this);
    this._internalVars.navBackClick = navBackClick.bind(this);
    this._internalVars.navCloseButtonClick = closeNav.bind(this);
    this._internalVars.setPanelHeight = setPanelHeight.bind(this);
    this._internalVars.accordionClick = accordionClick.bind(this);
}

function setEvents(): void {
    if (this._internalVars.skipToContent && (!document.body.classList.contains("with-unsupported-browser") || !document.querySelector(".unsupported-browser"))) {
        this._internalVars.skipToContent.addEventListener("blur", this._internalVars.skipToContentBlur);
        this._internalVars.skipToContent.addEventListener("focus", this._internalVars.skipToContentFocus);
    }

    if (this._internalVars.headerMenuLinks.length > 0) {
        Array.prototype.forEach.call(this._internalVars.headerMenuLinks, (link) => {
            link.addEventListener("click", this._internalVars.menuClick);
        });
        
        window.addEventListener("resize", this._internalVars.headerHoverLinksWidth);
    }

    this._internalVars.headerLogo.addEventListener("click", closeNav.bind(this));

    if (this._internalVars.navBg) {
        this._internalVars.navBg.addEventListener("click", this._internalVars.navBgClick);
    }

    if (this._internalVars.navTier1Links.length > 0) {
        Array.prototype.forEach.call(this._internalVars.navTier1Links, (link) => {
            link.addEventListener("click", this._internalVars.navTier1MenuClick);
        });
    }
    
    if (this._internalVars.navBack) {
        this._internalVars.navBack.addEventListener("click", this._internalVars.navBackClick);
    }

    if (this._internalVars.accordions.length > 0) {
        Array.prototype.forEach.call(this._internalVars.accordions, (accordion) => {
            new GdkAccordion({
                content: accordion,
                forceOpenSingleAccordionElement : true
            });
            this._internalVars.forceOpen = this._internalVars.nav.querySelector(".force-open");
            
            const accordionContentElements = accordion.querySelectorAll(".accordion-content");
            
            if (accordionContentElements.length > 0) {
                Array.prototype.forEach.call(accordionContentElements, (contentElement) => {
                    const contentLinks = contentElement.querySelectorAll("li a");

                    if (contentLinks.length > 0) {
                        Array.prototype.forEach.call(contentLinks, (link) => {
                            link.addEventListener("click", closeNav.bind(this));
                        });
                    }
                });
            }

            const accordionHeadlines = accordion.querySelectorAll(".accordion-headline");
            
            if (accordionHeadlines.length > 0) {
                Array.prototype.forEach.call(accordionHeadlines, (headline) => {
                    headline.addEventListener("click", this._internalVars.accordionClick);
                });
            }
        });
    }
    
    if (this._internalVars.closeButtons.length > 0) {
        Array.prototype.forEach.call(this._internalVars.closeButtons, (button) => {
            button.addEventListener("click", this._internalVars.navCloseButtonClick);
        });
    }
    
    window.addEventListener("resize", this._internalVars.setPanelHeight);
}

function removeEvents(): void {
    if (this._internalVars.skipToContent && (!document.body.classList.contains("with-unsupported-browser") || !document.querySelector(".unsupported-browser"))) {
        this._internalVars.skipToContent.removeEventListener("blur", this._internalVars.skipToContentBlur);
        this._internalVars.skipToContent.removeEventListener("focus", this._internalVars.skipToContentFocus);
    }

    if (this._internalVars.headerMenuLinks.length > 0) {
        Array.prototype.forEach.call(this._internalVars.headerMenuLinks, (link) => {
            if (link.querySelector(".header-hover-link")) {
                link.removeEventListener("mouseenter", this._internalVars.menuHover);
                link.removeEventListener("mouseleave", this._internalVars.menuLeave);
            }
            link.removeEventListener("click", this._internalVars.menuClick);
        });

        window.removeEventListener("resize", this._internalVars.headerHoverLinksWidth);
    }

    this._internalVars.headerLogo.removeEventListener("click", closeNav.bind(this));

    if (this._internalVars.navBg) {
        this._internalVars.navBg.removeEventListener("click", this._internalVars.navBgClick);
    }

    if (this._internalVars.navTier1Links.length > 0) {
        Array.prototype.forEach.call(this._internalVars.navTier1Links, (link) => {
            link.removeEventListener("click", this._internalVars.navTier1MenuClick);
        });
    }

    if (this._internalVars.navBack) {
        this._internalVars.navBack.removeEventListener("click", this._internalVars.navBackClick);
    }

    if (this._internalVars.accordions.length > 0) {
        Array.prototype.forEach.call(this._internalVars.accordions, (accordion) => {
            new GdkAccordion({
                content: accordion,
                forceOpenSingleAccordionElement : true
            });
            this._internalVars.forceOpen = this._internalVars.nav.querySelector(".force-open");

            const accordionContentElements = accordion.querySelectorAll(".accordion-content");

            if (accordionContentElements.length > 0) {
                Array.prototype.forEach.call(accordionContentElements, (contentElement) => {
                    const contentLinks = contentElement.querySelectorAll("li a");

                    if (contentLinks.length > 0) {
                        Array.prototype.forEach.call(contentLinks, (link) => {
                            link.addEventListener("click", closeNav.bind(this));
                        });
                    }
                });
            }
        });
    }

    if (this._internalVars.closeButtons.length > 0) {
        Array.prototype.forEach.call(this._internalVars.closeButtons, (button) => {
            button.removeEventListener("click", this._internalVars.navCloseButtonClick);
        });
    }

    window.removeEventListener("resize", this._internalVars.setPanelHeight);
}

function skipToContentFocus(): void {
    this._internalVars.header.style.top = "2rem";
}

function skipToContentBlur(): void {
    this._internalVars.header.style.top = "0";
}

function initHeaderLinks(): void {
    Array.prototype.forEach.call(this._internalVars.headerMenuLinks, (link) => {
        link.setAttribute("aria-expanded", "false");
    });
    headerHoverLinksWidth.call(this);
}

function applyAriaHasPopupTier1Links(): void {
    Array.prototype.forEach.call(this._internalVars.navTier1Links, (link) => {
        if (link.hasAttribute("data-nav-items-trigger") &&  !link.hasAttribute("aria-haspopup")) {
            link.setAttribute("aria-haspopup", "menu");
        }
    });
}

function headerHoverLinksWidth(): void {
    if (window.innerWidth > 999 && this._internalVars.navState === "mobile") {
        this._internalVars.navState = "desktop";
        Array.prototype.forEach.call(this._internalVars.headerMenuLinks, (link) => {
            if (link.querySelector(".header-hover-link")) {
                const text = link.querySelector(".header-hover-link");
                text.classList.remove("header-hover-link");
                text.style.maxWidth = `${link.offsetWidth}px`;
                text.classList.add("header-hover-link");
            }
        });
    } else if (window.innerWidth < 1000) {
        this._internalVars.navState = "mobile";
    }
}

function closeNav(): void {
    this._internalVars.navOpen = false;
    this._internalVars.menuOpen = false;
    closePanels.call(this);
    this._internalVars.header.classList.remove("open");
    this._internalVars.nav.classList.remove("open");
    this._internalVars.hamburgerMenu.classList.remove("open");
    Array.prototype.forEach.call(this._internalVars.headerMenuLinks, (link) => {
        link.setAttribute("aria-expanded", "false");
    });
    Array.prototype.forEach.call(this._internalVars.panels, (panel) => {
        Array.prototype.forEach.call(panel.querySelectorAll("ul"), (ul) => {
            ul.removeAttribute("tabindex");
        });
    });
    this._internalVars.body.removeAttribute("style");
    this._internalVars.html.removeAttribute("style");
}

function closePanels(): void {
    if (this._internalVars.navTier2) {
        closeTier2.call(this);   
    }
    Array.prototype.forEach.call(this._internalVars.panels, (panel) => {
        panel.classList.add("close");
        panel.setAttribute("aria-hidden", "true");
        panel.classList.remove("open");
        panel.style.display = "none";
    });
    Array.prototype.forEach.call(this._internalVars.headerMenuLinks, (link) => {
        link.classList.remove("open");
    });
    this._internalVars.currentOpenPanel = null;
}

function closeTier2(): void {
    this._internalVars.navMenu.classList.remove("open");
    this._internalVars.navTier1.style.display = "block";
    this._internalVars.navTier2.style.transform = "translateX(0)";

    Array.prototype.forEach.call(this._internalVars.navTier2Panels, (panel) => {
        panel.classList.remove("open");
    });
    
    if (this._internalVars.forceOpen) {
        this._internalVars.forceOpen.querySelector(".accordion-headline").setAttribute("aria-expanded", "false");
        this._internalVars.forceOpen.querySelector(".accordion-content-container").setAttribute("aria-hidden", "true");
    }
    
    this._internalVars.navTier2.style.display = "none";
    closeAccordions.call(this);
}

function closeAccordions(): void {
    Array.prototype.forEach.call(this._internalVars.navTier2.querySelectorAll(".accordion li"), (accordion) => {
        if (accordion.classList.contains("open")) {
            accordion.classList.remove("open");
            accordion.querySelector(".accordion-headline").setAttribute("aria-exapanded", "false");
            accordion.querySelector(".accordion-content-container").setAttribute("aria-hidden", "true");
            accordion.querySelector(".accordion-content-container").style.display = "none";
        }
    });
}

function openNav(): void {
    if (this._internalVars.navOpen === false) {
        this._internalVars.navOpen === true;
        this._internalVars.header.classList.add("open");
        this._internalVars.nav.classList.add("open");
        
        Array.prototype.forEach.call(this._internalVars.panels, (panel) => {
            Array.prototype.forEach.call(panel.querySelectorAll("ul"), (ul) => {
                ul.setAttribute("tabindex", "-1");  
            });
        });

        if (window.innerWidth < 1000) {
            this._internalVars.body.style.position = "fixed";
            this._internalVars.html.style.overflow = "hidden";
        } else {
            this._internalVars.body.removeAttribute("style");
            this._internalVars.html.removeAttribute("style");
        }
    }
}

function openNavPanel(panel: HTMLElement): void {
    if (this._internalVars.currentOpenPanel !== panel) {
        const dataAttr = panel.getAttribute("data-side-panel");

        Array.prototype.forEach.call(this._internalVars.headerMenuLinks, (link) => {
            link.setAttribute("aria-expanded", "false");
            if (dataAttr === link.getAttribute("data-side-panel-trigger")) {
                link.setAttribute("aria-expanded", "true");
            }
        });
        
        openNav.call(this);
        closePanels.call(this);
        panel.classList.remove("close");
        panel.style.display = "block";
        panel.setAttribute("aria-hidden", "false");
        toggleHamburgerMenu.call(this, panel);
        this._internalVars.currentOpenPanel = panel;

        setTimeout(() => {
            panel.classList.add("open");
            if (panel.querySelector(".nav-primary-tier")) {
                if (this._internalVars.navTier2) {
                    if (this._internalVars.navTier1Li.length < 2) {
                        this._internalVars.navBack.classList.add("hidden");
                        openTier2Panel.call(this, this._internalVars.navTier2Panels[0]);
                    } else {
                        this._internalVars.navBack.classList.remove("hidden");
                    }   
                }
            }
        }, 100);
    }
}

function openTier2(): void {
    this._internalVars.navMenu.classList.add("open");
}

function openTier2Panel(panel: HTMLElement): void {
    panel.classList.add("open");
    this._internalVars.navTier2.style.display = "block";
    openTier2.call(this);
    if (this._internalVars.forceOpen) {
        this._internalVars.forceOpen.querySelector(".accordion-headline").setAttribute("aria-expanded", "true");
        this._internalVars.forceOpen.querySelector(".accordion-content-container").setAttribute("aria-hidden", "false");
    }
    setTimeout(() => {
        this._internalVars.navTier1.style.display = "none";
        this._internalVars.navTier2.style.transform = "translateX(100%)";
        setPanelHeight.call(this);
        if (this._internalVars.navBack.classList.contains("hidden")) {
            const openPanel = this._internalVars.navTier2.querySelector(".open");
            const firstAccordion = openPanel.querySelector(".accordion-headline");
            firstAccordion.classList.add("keyboard-focus");
            firstAccordion.focus();
        } else {
            this._internalVars.navBack.querySelector("a").focus();
        }
    }, 500);
}

function setPanelHeight(): void {
    const additionPadding = 20;
    if (this._internalVars.currentOpenPanel !== null) {
        const openTier2Panel = this._internalVars.nav.querySelector(".nav-secondary-panel.open");
        
        if (openTier2Panel) {
            openTier2Panel.style.height = `${this._internalVars.currentOpenPanel.offsetHeight - openTier2Panel.offsetTop - additionPadding}px`;
        }
    }
}

function toggleHamburgerMenu(panel: HTMLElement): void {
    const panelAttr = panel.getAttribute("data-side-panel");
    Array.prototype.forEach.call(this._internalVars.headerMenuLinks, (link) => {
        if (link.getAttribute("data-side-panel-trigger") === panelAttr) {
            link.classList.add("open");
            this._internalVars.menuOpen = true;
        } else {
            link.classList.remove("open");
            this._internalVars.menuOpen = false;
        }
    });
}

function menuClick(e:{currentTarget: HTMLElement; preventDefault: Function;}): void {
    e.preventDefault();
    const clickedElement = e.currentTarget;
    
    if (this._internalVars.menuOpen === true && clickedElement.classList.contains("hamburger-menu")) {
        closeNav.call(this);
    } else {
        const panel = clickedElement.getAttribute("data-side-panel-trigger");
        
        if (panel && panel !== "") {
            let panelElement = null;
            Array.prototype.forEach.call(this._internalVars.panels, (navPanel) => {
                if (navPanel.getAttribute("data-side-panel") === panel) {
                    panelElement = navPanel;
                }
            });
            if (panelElement !== null) {
                clickedElement.setAttribute("aria-expanded", "true");
                openNavPanel.call(this, panelElement);
            }
        } 
    }
}

function navTier1MenuClick(e:{preventDefault: Function; currentTarget: HTMLElement;}): void {
    const clickedElement = e.currentTarget;
    const tier2Panel = clickedElement.getAttribute("data-nav-items-trigger");
    let tier2PanelElement = null;

    Array.prototype.forEach.call(this._internalVars.navTier2Panels, (panel) => {
        if (panel.getAttribute("data-nav-items") === tier2Panel) {
            tier2PanelElement = panel;
        }
    });

    if (tier2PanelElement !== null) {
        e.preventDefault();
        openTier2Panel.call(this, tier2PanelElement);
    } else {
        closeNav.call(this);
    }
}

function navBackClick(e:{preventDefault: Function;}): void {
    e.preventDefault();
    closeTier2.call(this);
    setTimeout(() => {
        this._internalVars.navTier1Li[0].querySelector("a").focus();
    }, 500);
}

function accordionClick(e:{currentTarget: HTMLElement;}): void {
    const element = e.currentTarget.parentElement;
    setTimeout(() => {
        const elementTop = element.offsetTop;
        const panelTop = element.parentElement.parentElement.offsetTop;
        scrollTo(this._internalVars.nav.querySelector(".nav-secondary-panel.open"), elementTop - panelTop, 400);
    }, 400);
}

function scrollTo(element: HTMLElement, to: number, duration: number): void {
    if (duration <= 0) return;
    const difference = to - element.scrollTop;
    const perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

Version.initGdkNPM(component, versions, GdkNavigation);

export { GdkNavigation };