import appState from "@gdk/app-state";
import baseComponent from "@gdk/base-component";
import Version from "@gdk/version";
import { IBaseComponentOptions } from "@gdk/base-component";

const component = "Tabs";
const versions = [
    { version: "2.6.3", release: "3.17.23"},
    { version: "2.6.2", release: "3.10.23"},
    { version: "2.6.1", release: "2.17.23"},
    { version: "2.6.0", release: "8.12.22"},
    { version: "2.5.0", release: "7.22.22"}
];

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK Tabs : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting: "initialTabOpen",
        isRequired: false,
        validate: "type",
        possibleValues: ["number"],
        errorMessage: ["GDK Tabs : initialTabOpen must be set to a number"]
    },
    {
        setting: "tabClicked",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK Tabs : tabClicked must be a function"]
    }
];

export interface ITabsOptions extends IBaseComponentOptions {
    initialTabOpen?: number;
    tabClicked?: (currentNode:HTMLElement) => void;
}

/**
 * @desc GDK Tabs JavaScript Class
 *
 * @example <caption>JS Instantiation</caption>
 * var tabs = new GDK.Tabs({
 *     content: "#your-tabs-id"
 * })
 *
 * @example <caption>JS Instantiation with initial open tab</caption>
 * var tabs = new GDK.Tabs({
 *     content: "#your-tabs-id",
 *     initialTabOpen: 2 // default 1
 * })
 *
 * @example <caption>JS Instantiation with callback</caption>
 * var tabs = new GDK.Tabs({
 *     content: "#your-tabs-id",
 *     tabClicked: function(currentNode) {
 *         //if tab node has specific ID
 *         if(currentNode.id === "tab-first") {
 *             console.log(currentNode);
 *         }
 *         console.log("Tab change");
 *     }
 * })
 */
class GdkTabs {
    _internalVars: {
        contentType: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";
        node: HTMLElement;
        panels: NodeList;
        tabs: NodeList;
        breakpoint: number;
    };

    readonly _defaults: {
        initialTabOpen: number;
    };

    readonly _options: ITabsOptions;

    /**
     * @param {Object} options Settings for the instantiation
     *
     * @param {string|HTMLElement} options.content A reference to the component node
     *
     * @param {number} [options.initialTabOpen=1] The number index of the tab to display on initialization
     *
     * @param {function} [options.tabClicked] A callback function that gets fired when a tab is clicked to open
     */
    constructor(options: ITabsOptions) {
        /**
         * @ignore
         */
        this._internalVars = {
            node: null,
            tabs: null,
            panels: null,
            breakpoint: 767,
            contentType: null
        };

        //options with defaults set
        /**
         * @ignore
         */
        this._defaults = {
            initialTabOpen: 1
        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            /**
             * @ignore
             */
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            appState.windowSize = { width: window.innerWidth || document.documentElement.clientWidth, height: window.innerHeight || document.documentElement.clientHeight };
            init.call(this);
            setLocalVars.call(this);
            setEvents.call(this);
            openInitialTab.call(this);
            scrollHandler.call(this);


            Array.prototype.forEach.call(this._internalVars.tabs, (element) => {
                element.setAttribute("role", "tab");
            });

            Array.prototype.forEach.call(this._internalVars.panels, (element) => {
                element.setAttribute("role", "tabpanel");
            });
        }
    }

    //Public Methods

    /**
     * @desc destroy() Removes the node from the dom and any events attached
     * 
     * @example
     * tabs.destroy();
     */
    destroy(): void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (const variableKey in this) {
            if (Object.prototype.hasOwnProperty.call(this, variableKey)) {
                delete this[variableKey];
            }
        }
    }

}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars(): void {
    this._internalVars.tabsContainer = this._internalVars.node.querySelector(".tabs-container");
    this._internalVars.tabs = this._internalVars.node.querySelectorAll(".tab");
    this._internalVars.panels = this._internalVars.node.querySelectorAll(".panel");
    this._internalVars.leftChevron = this._internalVars.node.querySelector(".tabs-left-chevron");
    this._internalVars.rightChevron = this._internalVars.node.querySelector(".tabs-right-chevron");

    this._internalVars.tabClickHandler = tabClickHandler.bind(this);
    this._internalVars.scrollHandler = scrollHandler.bind(this);
    this._internalVars.scrollLeft = scrollLeft.bind(this);
    this._internalVars.scrollRight = scrollRight.bind(this);
    this._internalVars.tabsContainerScrollTo = tabsContainerScrollTo.bind(this);
}

/**
 * init()
 * sets the node var and creates the chevron elements
 */
function init(): void {
    //determine the type of content passed in
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }

    const leftChevronSpan = document.createElement("span");
    const rightChevronSpan = document.createElement("span");

    leftChevronSpan.classList.add("icon-chevron-double-left");
    leftChevronSpan.classList.add("tabs-left-chevron");
    rightChevronSpan.classList.add("icon-chevron-double-right");
    rightChevronSpan.classList.add("tabs-right-chevron");

    this._internalVars.node.appendChild(leftChevronSpan);
    this._internalVars.node.appendChild(rightChevronSpan);
    this._internalVars.titleCase;
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents(): void {
    //set tab click events
    Array.prototype.forEach.call(this._internalVars.tabs, (element) => {
        element.addEventListener("click", this._internalVars.tabClickHandler);
        addKeyboardFocus(element);
    });

    this._internalVars.tabsContainer.addEventListener("scroll", this._internalVars.scrollHandler);
    this._internalVars.leftChevron.addEventListener("click", this._internalVars.scrollLeft);
    this._internalVars.rightChevron.addEventListener("click", this._internalVars.scrollRight);
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents(): void {
    Array.prototype.forEach.call(this._internalVars.tabs, (element) => {
        element.removeEventListener("click", this._internalVars.tabClickHandler);
    });

    this._internalVars.tabsContainer.removeEventListener("scroll", this._internalVars.scrollHandler);
    this._internalVars.leftChevron.removeEventListener("click", this._internalVars.scrollLeft);
    this._internalVars.rightChevron.removeEventListener("click", this._internalVars.scrollRight);
}

/**
 * tabClickHandler()
 * Opens tab content for clicked tab and hides the rest
 */
function tabClickHandler(element: { preventDefault: Function; currentTarget: Node; }): void | boolean {
    appState.windowSize = { width: window.innerWidth || document.documentElement.clientWidth, height: window.innerHeight || document.documentElement.clientHeight };
    element.preventDefault();

    const currentNode = element.currentTarget;

    //get index of clicked tab
    let tabIndex: number;

    Array.prototype.forEach.call(this._internalVars.tabs, (tab, index) => {
        if (tab === currentNode) {
            tabIndex = index;
        }
    });

    if (this._options.tabClicked)
        if (this._options.tabClicked(currentNode) === false) return false;

    Array.prototype.map.call(this._internalVars.tabs, (element) => {
        if (element === currentNode) {
            element.classList.add("active");
            element.setAttribute("aria-selected", true);
        } else {
            element.classList.remove("active");
            element.setAttribute("aria-selected", false);
        }
    });

    Array.prototype.map.call(this._internalVars.panels, (element, i) => {
        if (i === tabIndex) {
            openPanel.call(this, element);
        } else {
            closePanel.call(this, element);
        }
    });

    appState.windowSize = { width: window.innerWidth || document.documentElement.clientWidth, height: window.innerHeight || document.documentElement.clientHeight };

    const scrollSpeed = 300;
    const tabParent = (currentNode as HTMLElement).parentElement;
    const tabParentWidth = tabParent.clientWidth;
    const halfTabParentWidth = tabParentWidth / 2;
    const tabBoundingClientRect = (currentNode as HTMLElement).getBoundingClientRect();
    let arrowWidth = 35;

    if (appState.mode !== "mobile") {
        arrowWidth = 0;
    }

    if (tabBoundingClientRect.left > halfTabParentWidth && tabBoundingClientRect.right > tabParentWidth) {
        const offset = (currentNode.parentNode as HTMLElement).scrollLeft + ((tabBoundingClientRect.right - tabParentWidth) + (arrowWidth / 2));
        this._internalVars.tabsContainerScrollTo(offset, scrollSpeed);
    } else if (tabBoundingClientRect.left < arrowWidth) {
        const offset = (currentNode as HTMLElement).offsetLeft - arrowWidth;
        this._internalVars.tabsContainerScrollTo(offset, scrollSpeed);
    }
}

/**
 * openPanel()
 * Opens panel at the same index as the clicked tab and hides the other panels
 */
function openPanel(element: HTMLElement): void {
    element.style.display = "block";
    setTimeout(function () {
        element.classList.add("active");
        element.setAttribute("aria-hidden", "false");
    }, 1);
}

/**
 * closePanel()
 * closes the past in panel
 */
function closePanel(element: HTMLElement): void {
    element.style.display = "none";
    element.classList.remove("active");
    element.setAttribute("aria-hidden", "true");
}

/**
 * scrollHandler()
 * hide or show chevrons and mobile scroll tab selection
 */
function scrollHandler(): void {
    if (this._internalVars.tabsContainer.scrollLeft === 0) {
        !this._internalVars.leftChevron.classList.contains("hidden") && this._internalVars.leftChevron.classList.add("hidden");
    } else {
        this._internalVars.leftChevron.classList.contains("hidden") && this._internalVars.leftChevron.classList.remove("hidden");
    }

    if ((Number(this._internalVars.tabsContainer.offsetWidth) + Number(this._internalVars.tabsContainer.scrollLeft)) >= (this._internalVars.tabsContainer.scrollWidth - 2)) {
        !this._internalVars.rightChevron.classList.contains("hidden") && this._internalVars.rightChevron.classList.add("hidden");
    } else {
        this._internalVars.rightChevron.classList.contains("hidden") && this._internalVars.rightChevron.classList.remove("hidden");
    }
}

/**
 * scrollLeft()
 * scrolls tabsContainer Left
 */
function scrollLeft(): void {
    this._internalVars.tabsContainerScrollTo((this._internalVars.tabsContainer.scrollLeft as number - 150), 600);
}


/**
 * scrollRight()
 * scrolls tabsContainer right
 */
function scrollRight(): void {
    this._internalVars.tabsContainerScrollTo((this._internalVars.tabsContainer.scrollLeft as number + 150), 600);
}

/**
 * scrollTo()
 * animates tabs container scroll
 */
function tabsContainerScrollTo(to: number, duration: number): void {
    const element = this._internalVars.tabsContainer,
        start = element.scrollLeft,
        change = to - start,
        startDate = +new Date(),
        easeInOut = function (t: number, b: number, c: number, d: number): number {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        },
        animateScroll = function (): void {
            const currentDate = +new Date();
            const currentTime = Number(currentDate - startDate);
            element.scrollLeft = Number(easeInOut(currentTime, start, change, duration));
            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            }
        };
    animateScroll();
}


function openInitialTab(): void {
    let tabIndex: number = this._options.initialTabOpen - 1;

    (tabIndex > this._internalVars.tabs.length) && (tabIndex = 0);

    Array.prototype.map.call(this._internalVars.tabs, (element, i) => {
        if (i === tabIndex) {
            element.classList.add("active");
            element.setAttribute("aria-selected", true);
            this._internalVars.tabsContainer.scrollLeft = (element.offsetLeft as number) + (-38);
        } else {
            element.classList.remove("active");
            element.setAttribute("aria-selected", false);
        }
    });

    Array.prototype.map.call(this._internalVars.panels, (element, i) => {
        element.removeAttribute("style");
        if (i === tabIndex) {
            openPanel.call(this, element);
        } else {
            closePanel.call(this, element);
        }
    });
}


/**
 * addKeyboardFocus()
 * add/remove focus class to keyboard focused and blurred elements
 */
function addKeyboardFocus(element: HTMLElement): void {
    let isClick = false;
    element.addEventListener("mousedown", function () {
        isClick = true;
    });
    element.addEventListener("focus", function () {
        !isClick && element.classList.add("keyboard-focus");
        isClick = false;
    });
    element.addEventListener("blur", function () {
        element.classList.remove("keyboard-focus");
    });
}

Version.initGdkNPM(component, versions, GdkTabs);

export { GdkTabs };