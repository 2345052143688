import BaseComponent from "@gdk/base-component";
import Version from "@gdk/version";
import {IBaseComponentOptions} from "@gdk/base-component";
import $ from "jquery";

const component = "Accordion";
const versions = [
    { version: "3.0.0", release: "8.24.23"},
    { version: "2.10.0", release: "8.17.23"},
    { version: "2.9.1", release: "8.10.23"},
    { version: "2.9.0", release: "7.27.23"},
    { version: "2.8.1", release: "7.20.23"}
];

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Accordion : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "initiallyOpenedElement",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Accordion : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "shouldCloseOthers",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Accordion : shouldCloseOthers must be set to a boolean"]
    },
    /**
     * Design Kit DEVELOPERS USE ONLY:
     *
     * forceOpenSingleAccordionElement is to be used only when there is a single, forced opened element of the accordion
     * Case Study: Mobile Navigation when there is a single Tier 1 element, which is forcefully opened in the mobile view,
     * so it won't require additional click to open when user use the navigation.
     *
     * NOTE: The force-open element DO NOT have accordion functionality to retract and stays expanded at all times.
     */
    {
        setting                 :   "forceOpenSingleAccordionElement",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Accordion : forceOpenSingleAccordionElement must be set as a boolean"]
    },
    {
        setting                 : "accordionOpenClicked",
        isRequired              : false,
        validate                : "type",
        possibleValues          : ["function"],
        errorMessage            : ["GDK Accordion : accordionOpenClicked must be a function"]
    },
    {
        setting                 : "accordionCloseClicked",
        isRequired              : false,
        validate                : "type",
        possibleValues          : ["function"],
        errorMessage            : ["GDK Accordion : accordionCloseClicked must be a function"]
    }
];

export interface IAccordionOptions extends IBaseComponentOptions {
    initiallyOpenedElement?: HTMLElement;
    shouldCloseOthers?: boolean;
    forceOpenSingleAccordionElement?: boolean;
    accordionOpenClicked?: (currentNode: HTMLElement) => void;
    accordionCloseClicked?: (currentNode: HTMLElement) => void;
}

/**
 * @desc GDK Accordion JavaScript Class
 *
 * @example <caption>JS Instantiation</caption>
 * var accordion1 = new GDK.Accordion({
 *     "content" : "#your-accordion-id",
 *     "initiallyOpenedElement" : "#your-element-id", //the li tag of the accordion section to initially open
 *     accordionOpenClicked : function(currentNode){
 *         //if tab node has specific class name
 *         if(currentNode.classList.contains("accordionTab112")) return false;
 *         console.log("Accordion opening");
 *     },
 *     accordionCloseClicked : function(currentNode){
 *         //if tab node has specific id
 *         if(currentNode.id=="accordionTab111") return false;
 *         console.log("Accordion closing");
 *     }
 * });
 */
class GdkAccordion {
    _internalVars: {
        node: HTMLElement;
        headline: HTMLElement;
        contentContainer: HTMLElement;
        content: HTMLElement;
        contentType: "undefined" | "object" | "boolean" | "number" | "string" | "function" | "symbol" | "bigint" | "domNode";
    };

    readonly _defaults: {
        shouldCloseOthers: boolean;
        forceOpenSingleAccordionElement: boolean;
    };

    readonly _options: IAccordionOptions;

    /**
     * These are settings for the instantiation.
     * @param {string|Object} content
     * A reference to the html accordion node
     *
     * @param {string|Object} initiallyOpenedElement
     * A reference to the html accordion li tag element that should be opened on start
     *
     * @param {boolean} [shouldCloseOthers="true"]
     * Set to true if only one accordion can be open at a time or set to false if consecutively opened accordions can remain open
     *
     * @param {function} [accordionOpenClicked]
     * A callback function that gets fired when an accordion tab opens up. Param currentNode is optional and refers to the target clicked.
     *
     * @param {function} [accordionCloseClicked]
     *  A callback function that gets fired when an accordion tab closes. Param currentNode is optional and refers to the target clicked.
     */
    constructor(options: IAccordionOptions) {
        /**
         * @ignore
         */
        this._internalVars = {
            node: null,//used for content item
            headline:null,
            contentContainer:null,
            content:null,
            contentType: null,
        };

        //options with defaults set
        /**
         * @ignore
         */
        this._defaults = {
            shouldCloseOthers : true,
            forceOpenSingleAccordionElement : false
        };

        //Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            /**
             * @ignore
             */
            this._options = BaseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (BaseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = BaseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            if (this._options.initiallyOpenedElement) {
                openInitialElements.call(this);
            }
            const allAccordionHeadlines = this._internalVars.node.querySelectorAll(".accordion-headline");
            Array.prototype.forEach.call(allAccordionHeadlines, function(el: HTMLElement) {
                if (!el.getAttribute("tabindex"))
                    el.setAttribute("tabindex", "0");

                el.setAttribute("role", "button");
            });
        }
    }

    //Public Methods

    /**
     * @desc Removes the node from the dom and any events attached
     */
    destroy():void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (const variableKey in this) {
            if (Object.prototype.hasOwnProperty.call(this, variableKey)) {
                delete this[variableKey];
            }
        }
    }
}

/**
 * openInitialElement()
 * a private method which is only invoked when the initiallyOpenedElement property is set.
 */
function openInitialElements():void {
    let element:HTMLElement;
    if (typeof this._options.initiallyOpenedElement === "string") {
        element = this._internalVars.node.querySelector(this._options.initiallyOpenedElement);
        openAccordionElement.call(this, element);
    } else if (typeof this._options.initiallyOpenedElement === "object") {
        element = this._options.initiallyOpenedElement;
        openAccordionElement.call(this, element);
    }
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents():void {

    if (this._internalVars.node.childElementCount == 1 && this._options.forceOpenSingleAccordionElement) {
        console.log("The single element will be forced opened");
        forceOpenAccordion(this._internalVars.node.children[0]);
    } else {
        Array.prototype.forEach.call(this._internalVars.headline, (el) => {
            el.addEventListener("click",this._internalVars.handler);
            el.addEventListener("keyup", this._internalVars.handler);
            el.addEventListener("keyup", function(e:{shiftKey: boolean; keyCode:number;}) {
                if (e.shiftKey && e.keyCode == 9 || e.keyCode == 9) {
                    el.classList.add("keyboard-focus");
                }
            });
            el.addEventListener("blur", function() {
                el.classList.remove("keyboard-focus");
            });
        });
    }
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents():void {
    Array.prototype.forEach.call(this._internalVars.headline, (el) => {
        el.removeEventListener("click",this._internalVars.handler);
    });
}

/**
 * toggleAccordion()
 * opens or closes an accordions content
 *
 * @param  {Object} node DOM node that was clicked
 */
function toggleAccordion(el:{type:string; keyCode:number; which:number; currentTarget:HTMLElement;}):boolean|void {
    if (el.type == "keypress" || el.type == "keyup" && ((el.keyCode || el.which) != 13)) {
        return;
    }

    const currentNode:HTMLElement = el.currentTarget;
    const parent:HTMLElement = currentNode.parentElement;
    const contentContainer:HTMLElement = parent.querySelector(".accordion-content-container");

    if (!contentContainer.parentElement.classList.contains("open")) {
        if (this._options.accordionOpenClicked)
            if (this._options.accordionOpenClicked(currentNode)===false) return false;
        if (this._options.shouldCloseOthers) {
            closeAccordions.call(this);
        }

        parent.classList.add("open");
        $(contentContainer).slideDown();
        currentNode.setAttribute("aria-expanded", "true");
        contentContainer.setAttribute("aria-hidden", "false");
    } else {
        if (this._options.accordionCloseClicked)
            if (this._options.accordionCloseClicked(currentNode)===false) return false;
        parent.classList.remove("open");
        $(contentContainer).slideUp();
        currentNode.setAttribute("aria-expanded", "false");
        contentContainer.setAttribute("aria-hidden", "true");
    }
}

function scrollToAccordion(e: {type:string; keyCode:number; which:number; currentTarget:HTMLElement;}): void {
    // We only want to run this on the "open one at a time" version
    if (!this._options.shouldCloseOthers) {
        return;
    }

    const currentNode:HTMLElement = e.currentTarget;
    const parent:HTMLElement = currentNode.parentElement;
    const contentContainer:HTMLElement = parent.querySelector(".accordion-content-container");

    if (!contentContainer.parentElement.classList.contains("open")) {
        return;
    }


    const element = e.currentTarget;
    const parentModal = element.closest(".modal.modal--show");
    // gives us enough time for the accordion close/open to complete and calculate our offsets
    const TIMEOUT_MS = 500;

    if (parentModal) {
        setTimeout(() => {
            // This is the only method that seems to actually scroll the overflow inside the modal.
            element.scrollIntoView({behavior: "smooth" });
        // 700 gives us enough time for the accordion close/open to complete and calculate our offsets
        }, TIMEOUT_MS);
        return;
    }

    setTimeout(() => {
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        // The sticky menu will sometimes be in the way, so we want to scroll a few pixels lower so the accordion isn't covered by it
        const yOffset = viewportWidth > 999 ? 70 : 10;
        // first value gets element distance from top of viewport
        // second value gets top of document to top of viewport
        const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
    }, TIMEOUT_MS);
}

/**
 * openAccordionElement(element)
 * @param element
 * @returns {boolean}
 *
 * private method to simply open the element that have been passed as parameter (no events)
 */
function openAccordionElement(element:HTMLElement):boolean | void {
    const contentContainer:HTMLElement = element.querySelector(".accordion-content-container");
    const nodeHeadline:HTMLElement = element.querySelector(".accordion-headline");

    if (!contentContainer.parentElement.classList.contains("open")) {
        if (this._options.accordionOpenClicked)
            if (this._options.accordionOpenClicked(nodeHeadline)===false) return false;
        if (this._options.shouldCloseOthers) {
            closeAccordions.call(this);
        }

        $(contentContainer).slideDown();
        element.classList.add("open");
        nodeHeadline.setAttribute("aria-expanded", "true");
        contentContainer.setAttribute("aria-hidden", "false");
    } else {
        if (this._options.accordionCloseClicked)
            if (this._options.accordionCloseClicked(nodeHeadline)===false) return false;
        element.classList.remove("open");
        $(contentContainer).slideUp();
        nodeHeadline.setAttribute("aria-expanded", "false");
        contentContainer.setAttribute("aria-hidden", "true");
    }
}

function forceOpenAccordion(el:HTMLElement):void {
    el.classList.add("force-open");
}

/**
 * closeAccordions()
 * closes all open accordions
 */
function closeAccordions():void {
    const openAccordions:HTMLElement = this._internalVars.node.parentNode.querySelectorAll(".accordion > li.open");
    Array.prototype.forEach.call(openAccordions, (el:HTMLElement) => {
        el.classList.remove("open");
        const contentContainer:HTMLElement = el.querySelector(".accordion-content-container");
        el.querySelector(".accordion-headline").setAttribute("aria-expanded", "false");
        contentContainer.setAttribute("aria-hidden", "true");
        $(contentContainer).slideUp();
    });
}

function onClick(e: {type:string; keyCode:number; which:number; currentTarget:HTMLElement;}): void {
    toggleAccordion.call(this, e);
    scrollToAccordion.call(this, e);
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars():void {
    //determine the type of content passed in
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.headline = this._internalVars.node.querySelectorAll(".accordion-headline");
    const allLIAccordions:HTMLElement = this._internalVars.node.parentNode.querySelectorAll(".accordion > li");

    Array.prototype.forEach.call(allLIAccordions, (el) => {
        if (el.classList.contains("open")) {
            el.querySelector(".accordion-headline").setAttribute("aria-expanded", "true");
            el.querySelector(".accordion-content-container").setAttribute("aria-hidden", "false");
        } else {
            el.querySelector(".accordion-headline").setAttribute("aria-expanded", "false");
            el.querySelector(".accordion-content-container").setAttribute("aria-hidden", "true");
        }
    });

    this._internalVars.handler = onClick.bind(this);
}

Version.initGdkNPM(component, versions, GdkAccordion);

export { GdkAccordion };