import Version from "@gdk/version";

//Global footer vars
let copyRight:HTMLElement,
    footerNewYear:Date,
    footerCurrentYear:number;

function init():void {
    setYear();
}

function setYear():void {
    copyRight = document.getElementById("footerDate");
    footerNewYear = new Date();
    footerCurrentYear = footerNewYear.getFullYear();
    copyRight.innerHTML = String(footerCurrentYear);
}

const component = "Footer";
const versions = [
    { version: "1.5.1", release: "3.18.22"},
    { version: "1.5.0", release: "2.25.22"},
    { version: "1.4.1", release: "12.17.21"},
    { version: "1.4.0", release: "05.14.21"},
    { version: "1.3.0", release: "01.29.21"},
];

Version.initGdkNPM(component, versions, {init});

export {init};